import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IState as IStateContainer } from './OtpAuthenticationFormContainer';
import { URL } from "../utils/rest";
import { FormInstance } from "antd/es/form/Form";

const OtpAuthenticationForm = (props: {
    visible?: boolean;
    onActiveMail: () => void;
    onActive2FA: () => void;
    onActiveErecetaMobileApp: () => void;
    onSubmit: (form: FormInstance) => void;
    onCancel: () => void;
    poolUserMobileAppBounded: () => void;
} & IStateContainer) => {

    const { t } = useTranslation(['otpAuthenticationForm']);
    const [form] = Form.useForm();

    return (
        <Modal
            title={<><FontAwesomeIcon icon={faLock} style={{ color: '#707070', marginRight: '0.5rem' }}/><span>{t('otpAuthenticationTitle')}</span></>}
            style={{ top: 40 }}
            onCancel={() => props.onCancel()}
            visible={props.visible}
            destroyOnClose
            width={586}
            cancelText={t('buttons:cancel')}
            footer={[<Button key='back' onClick={props.onCancel}>{t('buttons:cancel')}</Button>]}
        >
            {!props.otpErecetaMobileAppActive && !props.otpActive && <Row align='middle' justify='space-between' className='otp-option-container'>
                <Col span={19}>
                    <Row className='otp-option-title'>{t('email').toUpperCase()}</Row>
                    <Row>{props.email}</Row>
                </Col>
                <Col span={5}>
                    <Button style={{ width: '100%' }} disabled={props.otpType === 0} onClick={() => props.onActiveMail()}>{props.otpType === 0 ? t('active').toUpperCase() : t('buttons:activate').toUpperCase()}</Button>
                </Col>
            </Row>}
            {!props.otpErecetaMobileAppActive && <Row align='middle' justify='space-between' className='otp-option-container' >
                <Col span={16}>
                    <Row className='otp-option-title'>{t('app2fa').toUpperCase()}</Row>
                </Col>
                {(props.otpActive || props.otpType === 1) && <Col span={3}>
                    <Row justify='end'>
                        <Button type='link' size='small' onClick={() => props.onActive2FA()}>{t('linkAgain')}</Button>
                    </Row>
                </Col>}
                <Col span={5}>
                    <Row justify='end'>
                        <Button style={{ width: '100%' }} disabled={props.otpActive || props.otpType === 1} onClick={() => props.onActive2FA()}>{props.otpType === 1 ? t('active').toUpperCase() : t('buttons:activate').toUpperCase()}</Button>
                    </Row>
                </Col>
            </Row>}
            {/*!props.otpActive && <Row align='middle' justify='space-between' className='otp-option-container' >
                <Col span={16}>
                    <Row className='otp-option-title'>{t('erecetaMobileApp').toUpperCase()}</Row>
                </Col>
                {(props.otpErecetaMobileAppActive || props.otpType === 2) && <Col span={3}>
                    <Row justify='end'>
                        <Button type='link' size='small' onClick={() => props.onActiveErecetaMobileApp()}>{t('linkAgain')}</Button>
                    </Row>
                </Col>}
                <Col span={5}>
                    <Row justify='end'>
                        <Button style={{ width: '100%' }} disabled={props.otpErecetaMobileAppActive || props.otpType === 2} onClick={() => props.onActiveErecetaMobileApp()}>{props.otpType === 2 ? t('active').toUpperCase() : t('buttons:activate').toUpperCase()}</Button>
                    </Row>
                </Col>
            </Row>*/}

            {props.otpActive && <div className='qr-code-container'>
                <div>{t('qrInstructions')}</div>
                <img src={URL + '/file?qr'} />

                <Form form={form} className='qr-input-code'>
                    <Form.Item className='code-input' name='codeInput' rules={[{ required: true }]}>
                        <Input type='number' minLength={6} maxLength={6} placeholder={t('otpInputCode')} />
                    </Form.Item>
                    <Button type='primary' onClick={() => props.onSubmit(form)}>{t('buttons:enviar')}</Button>
                </Form>
                {props.errorMsg && <Alert style={{ width: '100%' }} message={props.errorMsg} type='error' showIcon/>}
            </div>}

            {props.otpErecetaMobileAppActive && <div className='qr-code-container'>
                <div>{t('qrInstructions')}</div>
                <img src={URL + '/file?qrapp'} onLoad={() => props.poolUserMobileAppBounded()}/>
                
                </div>
            }

        </Modal>
    );

}

export default OtpAuthenticationForm;