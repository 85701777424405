import React from 'react';

import { faEye, faPills, faUserInjured } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './HomePageContainer';
import Dispensation from './entities/dispensation';
import { Functions } from '../utils/utils';
import { Button, Col, Pagination, Row } from 'antd';
import EnumTransaccionAccion from '../enum/EnumTransaccionAccion';
import Patient from './entities/patient';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import EnumCodeIdentificationType from '../enum/EnumCodeIdentificationType';

interface IProps {
    onChangePage: (page: number) => void;
    onClickDispensation: (nombrePaciente: string, idPrescripcionGrupo: number) => void;
    onClickPatientPrescriptions: (nombrePaciente: string, codeidentificacion?: string) => void;
    onClickPatientNewPrescription: (idPaciente: number) => void;
}

const HomePage = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['homePage']);

    return (<div style={{overflowY: 'auto', height: '100%'}}>
        <Row gutter={[64, 64]} className='home_page__container' justify='space-between'>
            <Col style={{maxWidth: '800px'}}>
                <div className='dispensations-page'>
                    <b>{t('lastDispensations')}</b>
                    <div className='pagination'><Pagination size='small' hideOnSinglePage total={props.dataCount} current={props.page} onChange={props.onChangePage}/></div>
                </div>
                {props.dispensations?.length === 0 ?
                    <div className='no_data_container'>
                        <div className='no_data'>
                        <FontAwesomeIcon icon={faPills} className='icon' />
                        {t('noDispensations')}
                        </div>
                    </div>
                :
                    props.dispensations?.map((disp, i) => <Dispensacion key={i} dispensation={disp} onClickDispensation={props.onClickDispensation}/>)}

            </Col>
            <Col style={{minWidth: '500px', maxWidth: '700px'}}>
                <div className='dispensations-page'>
                    <b>{t('lastPatients')}</b>
                </div>
                {props.patients?.length === 0 ?
                    <div className='no_data_container'>
                        <div className='no_data'>
                        <FontAwesomeIcon icon={faUserInjured} className='icon' />
                        {t('noPatients')}
                        </div>
                    </div>
                :
                    props.patients?.map((pat, i) => <Paciente key={i} patient={pat} onClickPatientNewPrescription={props.onClickPatientNewPrescription} onClickPatientPrescriptions={props.onClickPatientPrescriptions}/>)
                }
                
            </Col>
            
        </Row>
        </div>
    )
}

const Dispensacion = (props: {dispensation: Dispensation, onClickDispensation: (nombrePaciente: string, idPrescripcionGrupo: number) => void;}) => {

    const { t } = useTranslation(['homePage']);

    const name = props.dispensation.nameNombre + ' ' + props.dispensation.nameApellido1 + (props.dispensation.nameApellido2 ? ' ' + props.dispensation.nameApellido2 : '');
    
    return (
        <Row>
            <div className='dispensation_container'>
                <div className='dispensation-date'>
                    <b><span>{Functions.dateToString(props.dispensation.dateAccion)}, {props.dispensation.dateAccion.toLocaleTimeString()}</span></b>
                    <Button className='button__details' icon={<FontAwesomeIcon icon={faEye} style={{ color: '#3F91F7', fontSize: '0.8rem' }} />}
                        onClick={() => props.onClickDispensation(name, props.dispensation.idPrescripcionGrupo)} />
                </div>
                <div>
                    {t('dispensed')} <b>{props.dispensation.valuePresentacion ? props.dispensation.valuePresentacion : (props.dispensation.nameDcpf ? props.dispensation.nameDcpf : props.dispensation.valueComposicionFmv)}</b> en <b>{props.dispensation.idFarmacia}</b>
                    {props.dispensation.typeAccion === EnumTransaccionAccion.SUSTITUIR &&
                    <>
                        {' '} con <span className='substitution'>{t('substitution')}</span>
                        <div>{t('replacedBy')} <b>{props.dispensation.valuePresentacionSust ? props.dispensation.valuePresentacionSust : props.dispensation.valueComposicionFmvSust}</b></div>
                    </>
                    }
                </div>
                <div>
                    {t('patient')} <b>{name}</b>
                </div>
            </div>
        </Row>
        
    )

}

const Paciente = (props: {
    patient: Patient, 
    onClickPatientPrescriptions: (nombrePaciente: string, codeidentificacion?: string) => void;
    onClickPatientNewPrescription: (idPaciente: number) => void;
}) => {
    const { t } = useTranslation(['homePage']);

    const name = props.patient.nameNombre + ' ' + props.patient.nameApellido1 + (props.patient.nameApellido2 ? ' ' + props.patient.nameApellido2 : '');

    return (
        <Row>
            <div className='dispensation_container'>
                <div className='dispensation-date'>
                    <span>{t('patientName')} <b>{name}</b></span>
                    <Button className='button__details' icon={<FontAwesomeIcon icon={faEye} style={{ color: '#3F91F7', fontSize: '0.8rem' }} />}
                        onClick={() => props.onClickPatientPrescriptions(name, props.patient.codeIdentificacion)}/>
                    {props.patient.hasConsentimiento &&
                    <Button className='button__details' type='primary' icon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.8rem' }} />}
                        onClick={() => props.onClickPatientNewPrescription(props.patient.idPaciente)}/>
                    }
                </div>
                <Row>
                    <Col span={6}>{t('codeIdentificationType:' + props.patient.typeIdentificacion)}<br/>
                        <b>{props.patient.typeIdentificacion === EnumCodeIdentificationType.MENOR ? '-' : props.patient.codeIdentificacion}</b></Col>
                    <Col span={10}>{t('birthDate')} <b>{Functions.dateToString(props.patient.dateNacimiento)}</b></Col>
                    <Col span={8}>{t('age')} <b>{Functions.calculateAge(props.patient.dateNacimiento)}</b></Col>
                </Row>
            </div>
        </Row>
        
    )
}

export default HomePage;