import React from 'react';

import { Button, Modal, Row, Col, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import PersonaSelected from './entities/personaSelected';

interface IProps {
    visible?: boolean;
    confirmConsentimientoPapel(): void;
    confirmConsentimientoEmail(): void;
    cancelConsentimiento(): void;
    hasEmailPacienteConsentiment?: boolean;
    patientName: string;
    currentTutor: number;
}

const ConfirmConsentInPerson = (props: IProps) => {

    const { t } = useTranslation(['consent']);

    const test = (
        props.currentTutor?
            <div className='prescription-confirmation-text'>{t('consent:consentSelectionTutor', {patientName: props.patientName})}</div>
        : <div className='prescription-confirmation-text'>{t('consent:consentSelection', {patientName: props.patientName})}</div>
    );

    const footer = (
        <Row justify={props.hasEmailPacienteConsentiment != true ? 'center':'space-between'}>
            <Col>
                <Popconfirm placement="bottom" title={t('consent:consentSelectionPapelOK')} onConfirm={() => props.confirmConsentimientoPapel()} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                    <Button type='primary'>
                        {t('buttons:consentimientoPresencial')}
                    </Button>
                </Popconfirm>            
            </Col>
            {props.hasEmailPacienteConsentiment == true &&
            <Col>
                <Button type='primary' onClick={props.confirmConsentimientoEmail}>
                    {t('buttons:consentimientoMail')}
                </Button>
            </Col>}
        </Row>
    );    

    return (
        <Modal
            title={t('consent:consentSelectionTitle')}
            onCancel={() => props.cancelConsentimiento()}
            bodyStyle={{ paddingTop: '0.5rem', paddingBottom: '1rem' }}
            width={600}            
            style={{ top: 40 }}
            visible={props.visible}
            footer={footer}
            destroyOnClose
        >
            <div className='prescription-confirmation'>
                {test}
            </div>
        </Modal>
    );
    
}
export default ConfirmConsentInPerson;