import React from 'react';

import { Button, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './IndexContainer';
import vidalVademecum from '../images/VIDAL_Vademecum_españa_FINAL.png';
import erecetaLogo from '../images/eRecetaLogo01_trans.png';
import logoDeloniaNd7 from '../images/logo_delonia_nd7.png';
import vidalCeMark from '../images/vidalCeMark.png';
import sello_sp_delonia from '../images/sello_sp_delonia.png';
import sello_rp_delonia from '../images/sello_rp_delonia.png';
import './login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope, faFileContract, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { URL } from '../utils/rest';
import { useMediaQuery } from 'react-responsive'


const Index = (props: {
    openContactForm: () => void; 
    onClickPrivacyPolicy: (fromContact: boolean) => void; 
    onClickLogin: () => void; 
    onClickTestUser: () => void;
    onClickVidalVademecum: () => void;
    onClickAltaPrescriptor: () => void;
}
& IStateContainer) => {

    const { t } = useTranslation(['login']);
    const isSmallDevice = useMediaQuery({ query: '(max-width: 500px)' })

    return (
    <>
        {props.loaded &&(<div className={'home-container'}>
            <div className='header'>
            <img src={erecetaLogo} onClick={() => window.open("https://www.delonia.com/ereceta/")} style={{ cursor: "pointer"}} alt=''/>
                <Space className='button-bar' size={'large'}>
                    <Tooltip title={t('accessTooltip')} > 
                        <Button type='link' className='danger' size='large' icon={isSmallDevice ? <FontAwesomeIcon icon={faUser} /> : undefined} danger block onClick={props.onClickLogin}>{isSmallDevice ? "" :t("accessButton")}</Button>
                    </Tooltip>

                    <Tooltip title={t('contactTooltip')} > 
                        <Button type='link' size='large' color='black' icon={isSmallDevice ? <FontAwesomeIcon icon={faEnvelope} /> : undefined} block onClick={props.openContactForm}>{isSmallDevice ? "" :t("contactButton")}</Button>
                    </Tooltip>
                    <Tooltip title={t('privacyTooltip')} > 
                        <Button type='link' size='large' color='black' icon={isSmallDevice ? <FontAwesomeIcon icon={faFileContract} /> : undefined} block onClick={() => {props.onClickPrivacyPolicy(false)}}>{isSmallDevice ? "" : t("privacyPolicyButton")}</Button>
                    </Tooltip>

                </Space>
            </div>
            <div className='body'>
            <div className='indexTextDiv'>
                <div className='indexTextDiv-firstText' dangerouslySetInnerHTML={{__html: t("firstIndexText")}}/>
                <div className='indexTextDiv-secondText'>{t("secondIndexText")}</div>
                <div className='indexTextDiv-thirdText'>
                    <span className='innerThirdText' dangerouslySetInnerHTML={{__html: t("thirdIndexText")}}/> 
                        <b><span className='innerThirdText' style={{color: '#cb1135',cursor: "pointer"}} onClick={() => props.onClickVidalVademecum()} >{"ViDAL Vademecum"}</span></b>
                    <span className='innerThirdText'  dangerouslySetInnerHTML={{__html: t("thirdIndexText2")}}/> 
                        <b className='innerThirdText' onClick={() => window.open(URL + '/pdf/Certificat_GMED_Juin_23.pdf', '_blank' )} style= {{cursor: "pointer"}}>{"marcado CE DM Class IIb"}</b>
                    <span className='innerThirdText'  dangerouslySetInnerHTML={{__html: t("thirdIndexText3")}}/> 
                </div>
                
            </div>
            <div className='indexImagesButtonsDiv'>
                <div className='imagesRow'>
                    <img src={vidalCeMark} onClick ={() => window.open(URL + '/pdf/Certificat_GMED_Juin_23.pdf', '_blank' )} style={{ cursor: "pointer"}} alt=''/>
                    <img src={sello_sp_delonia} onClick={() => window.open("https://www.cgcom.es/plataformas_receta_homologadas/sist-prescripcion/delonia")} style={{ cursor: "pointer"}} alt=''/>
                    <img src={sello_rp_delonia} onClick={() => window.open("https://www.cgcom.es/plataformas_receta_homologadas/repositorio/delonia")} style={{ cursor: "pointer"}}  alt=''/>

                </div>
                <div className='testUserButton' hidden={!props.botonTestVisible}>
                    <Button type='default' onClick={props.onClickTestUser}>{t("testUserButtonText")} <FontAwesomeIcon icon={faArrowRight} /></Button>
                </div>
                <div className='testUserButton' hidden={true /*props.botonTestVisible && !window.location.search.includes('?mostrarAlta')*/}>
                    <Button className='altaPrescriptor' type='default' onClick={props.onClickAltaPrescriptor}>{t("altaPrescriptorButtonText")} <FontAwesomeIcon icon={faArrowRight} /></Button>
                </div>
                <div className='accessUserButton'>
                    <Button type='default'  onClick={props.onClickLogin}><div><span dangerouslySetInnerHTML={{__html: t("accessUserButtonText")}}></span>  <FontAwesomeIcon icon={faArrowRight} /></div></Button>
                </div>
            </div>
            <div className='logo-container'>
                <img src={logoDeloniaNd7} onClick={() => window.open("https://www.delonia.com/")} style={{ cursor: "pointer"}} alt=''/>
                <img src={vidalVademecum} onClick={() => window.open("https://www.vademecum.es/")} style={{ cursor: "pointer"}} alt=''/>
            </div>
            </div>
        </div>)}
        </>
    );
}

export default Index;
