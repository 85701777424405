import React, { ClipboardEvent, KeyboardEvent, RefObject, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Alert, Form, Input, Button, FormInstance } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'antd/lib/modal/Modal';
import EnumCodeResultLogin from '../../enum/EnumCodeResultLogin';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';

const SignOtpModal = (props: {
    visible?: boolean;
    onSignOtpContinue: (values: Store) => void;
    onValidationOtp: () => void;
    onClose: () => void;
    tryAgain: () => void;
    sendByEmail?: () => void;
    otpType: number;
    email?: string;
    codeResult?: number;
    dispositivo?: string;
    errorMessage?: string;
    warningMessage?: string;
}) => {

    const { t } = useTranslation(['signPrescripction']);
    const [formOTP] = Form.useForm();

    let isCommand: boolean; // controlamos si se ha pulsado la tecla command, para no desacivar el command+V/Ctrl+V
    isCommand = false;

    const password_txt_Key_1 = useRef<Input>(null);
    const password_txt_Key_2 = useRef<Input>(null);
    const password_txt_Key_3 = useRef<Input>(null);
    const password_txt_Key_4 = useRef<Input>(null);
    const password_txt_Key_5 = useRef<Input>(null);
    const password_txt_Key_6 = useRef<Input>(null);

    const otpRefsArray = [password_txt_Key_1, password_txt_Key_2, password_txt_Key_3, password_txt_Key_4, password_txt_Key_5, password_txt_Key_6];

    useEffect(() => {
        if (props.visible && password_txt_Key_1) {
            setTimeout(() => password_txt_Key_1.current?.focus(), 500);
        }
    }, [props.visible, password_txt_Key_1]);

    const onOTPPaste = (event: ClipboardEvent<HTMLInputElement>, form: FormInstance) => {
        const pastedValue = event.clipboardData.getData('Text');
        if (pastedValue && pastedValue.length === 6) {
            form.setFieldsValue({
                txt_Key_1: pastedValue.charAt(0),
                txt_Key_2: pastedValue.charAt(1),
                txt_Key_3: pastedValue.charAt(2),
                txt_Key_4: pastedValue.charAt(3),
                txt_Key_5: pastedValue.charAt(4),
                txt_Key_6: pastedValue.charAt(5),
            });
        }
    }

    const changeInputActive = (event: KeyboardEvent<HTMLInputElement>, form: FormInstance, field: string, refIndex: number) => {
        if (!isCommand) {
            var regex = /[0-9]|\./;
            if (otpRefsArray.length && regex.test(event.key)) {
                if (refIndex+1) {
                    otpRefsArray[refIndex+1]?.current?.focus();
                }
            } else if (event.key === "Backspace" || event.key === "Delete") {
                if (refIndex-1 >= 0) {
                    otpRefsArray[refIndex-1]?.current?.focus();
                }
            } else {
                form.setFieldsValue({
                    [field]: ''
                });
            }
        }
    }
    
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        //alert ("Is comand : " + e.metaKey)
        //alert ("Is ctrl : " + e.ctrlKey)
        //alert ("Evaluamos  : " + (e.metaKey || e.ctrlKey))
        if (e.metaKey || e.ctrlKey) {
            isCommand = true;
        } else {
            isCommand = false;
        }
    } 

    const renderOTPFormItem = (field: string, ref: React.RefObject<Input>, refIndex: number) => (
        <Form.Item name={field} rules={[{ required: true }]}>
            <Input ref={ref} type='password' className='login_otp_text' maxLength={1} onPaste={(event) => onOTPPaste(event, formOTP)} onKeyDown={handleKeyDown} onKeyUp={(event) => changeInputActive(event, formOTP, field, refIndex)} />
        </Form.Item>)

    return (
        <Modal visible={props.visible} footer={null} onCancel={() => props.onClose()}
            bodyStyle={{paddingTop:'0.5rem', paddingBottom: '1.5rem'}} width={395} focusTriggerAfterClose={false}>
            <div className='otpContainer'>
                <FontAwesomeIcon icon={faShieldAlt} style={{fontSize: '4rem', color: '#F6DA4C'}}/>

                {props.otpType === 2 && !props.codeResult && <>
                    <div className='signOtpTitle'>{t('signApp', {dispositivo: props.dispositivo})}</div>
                </>}

                {props.otpType !== 2 && props.codeResult !== EnumCodeResultLogin.CODE_OTP_CADUCADO && 
                    <>
                        <div className='signOtpTitle'>{props.otpType === 0 ? t('signOtp') : t('signTotp')}</div>
                        {props.otpType === 0 && <div> <b>{props.email}</b> </div>}
                        <Form form={formOTP} layout='inline' size='large' onFinish={props.onSignOtpContinue} onFinishFailed={props.onValidationOtp}>
                            {renderOTPFormItem('txt_Key_1', password_txt_Key_1, 0)}
                            {renderOTPFormItem('txt_Key_2', password_txt_Key_2, 1)}
                            {renderOTPFormItem('txt_Key_3', password_txt_Key_3, 2)}
                            {renderOTPFormItem('txt_Key_4', password_txt_Key_4, 3)}
                            {renderOTPFormItem('txt_Key_5', password_txt_Key_5, 4)}
                            {renderOTPFormItem('txt_Key_6', password_txt_Key_6, 5)}
                            
                            {props.warningMessage && <Alert message={props.warningMessage} type='warning' showIcon className='warning_Message'/>}
                            {props.errorMessage && <Alert message={props.errorMessage} type='error' showIcon className='error_Message'/>}
                        
                            <span style={{textAlign: 'center', width: '100%'}}><Button type='primary' htmlType='submit' size='middle' 
                                style={(props.warningMessage || props.errorMessage) ? {marginTop:'1.5rem'} : undefined}>{t('buttons:continue')}</Button></span>
                        </Form>
                    </>
                }
                
                {props.codeResult === EnumCodeResultLogin.CODE_OTP_CADUCADO && 
                    <div className='access__expired'>
                        {props.errorMessage && <Alert message={props.errorMessage} type='error' showIcon/>}
                        <Button onClick={props.tryAgain} style={{marginTop: '1rem'}} size='middle'>{t('login:tryAgainButton')}</Button>
                    </div>
                }

                {/** Envío por email **/}
                {props.otpType !== 0  && props.codeResult !== EnumCodeResultLogin.CODE_OTP_CADUCADO && props.sendByEmail &&
                    <div className='access__expired'>
                        <Button onClick={props.sendByEmail} type='link' size='middle'>{t('login:otpUseEmail')}</Button>
                    </div>
                }
                
            </div>
        </Modal>
    )
}

export default SignOtpModal;