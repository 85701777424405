import React from 'react';

import {useTranslation} from 'react-i18next';
import {FormUtils, Functions} from '../../utils/utils';
import {Form, Button, Row, Col, Input, DatePicker, Select, Space} from 'antd';
import {Option} from 'rc-select';
import {FormInstance} from 'antd/lib/form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faPen} from '@fortawesome/free-solid-svg-icons/faPen';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {IState as IStateContainer} from './newPatientContainer';
import Modal from 'antd/lib/modal/Modal';
import EnumGender from '../../enum/EnumGender';
import EnumCodeIdentificationType from '../../enum/EnumCodeIdentificationType';
import TutorData from './entities/tutorData';
import PatientData from './entities/patientData';
import moment from 'moment';
import {Moment} from 'moment/moment';
import { UpOutlined } from '@ant-design/icons';
import Flag from 'react-flagkit';

const NewPatient = (props: {
    visible?: boolean;
    currentTutor?: TutorData;
    patient?: PatientData;
    editPatient?: boolean;
    onCancel: () => void;
    fromExistingPerson?: boolean;
    onSubmit: (form: FormInstance) => void;
    onChangeDocType: (docType: number) => void;
    getMutualidades: (idAseguradora?: number) => void;
    getAseguradoras: (idMutualidad?: number) => void;
    addTutor: () => void;
    editTutor: () => void;
    deleteTutor: () => void;
    tutorConsentimiento?: boolean;
    getTutorConsentimiento: (idPersona: number) => void;
} & IStateContainer) => {

    const {t} = useTranslation(['newPatientPrescription']);
    const [form] = Form.useForm();

    const genderList = [
        <Option key={EnumGender.MALE} value={EnumGender.MALE}>{t('gender:' + EnumGender.MALE)}</Option>,
        <Option key={EnumGender.FEMALE} value={EnumGender.FEMALE}>{t('gender:' + EnumGender.FEMALE)}</Option>,
        <Option key={EnumGender.OTHER} value={EnumGender.OTHER}>{t('gender:' + EnumGender.OTHER)}</Option>
    ];

    const typeDocList = [
        <Option key={EnumCodeIdentificationType.DNI}
                value={EnumCodeIdentificationType.DNI}>{t('codeIdentificationType:' + EnumCodeIdentificationType.DNI)}</Option>,
        <Option key={EnumCodeIdentificationType.NIE}
                value={EnumCodeIdentificationType.NIE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.NIE)}</Option>,
        <Option key={EnumCodeIdentificationType.MENOR}
                value={EnumCodeIdentificationType.MENOR}>{t('codeIdentificationType:' + EnumCodeIdentificationType.MENOR)}</Option>,
        <Option key={EnumCodeIdentificationType.PASAPORTE}
                value={EnumCodeIdentificationType.PASAPORTE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.PASAPORTE)}</Option>
    ];

    const onSelectAseguradora = () => {
        const values = form.getFieldsValue();
        values.modality = undefined;
        props.getMutualidades(form.getFieldValue('idAseguradora'));
    }

    const onSelectMutualidad = () => {
        const values = form.getFieldsValue();
        values.modality = undefined;
        props.getAseguradoras(form.getFieldValue('idMutualidad'));
    }

    const onChangeDocType = (docType: number) => {
        form.setFieldsValue({...form.getFieldsValue(), codeIdentification: undefined});
        props.onChangeDocType(docType);
    }


    const onDatePickerChange = (dt: Moment|null) => {
        /*if(!dt || (props.editPatient || props.fromExistingPerson)) {
            return;
        }

        if(Functions.calculateAge(dt.toDate()) < 18) {
            form.setFieldsValue({...form.getFieldsValue(), codeIdentification: undefined, typeDoc:  3});
            props.onChangeDocType(EnumCodeIdentificationType.MENOR);
        } else {
            const docType = form.getFieldValue('typeDoc');
            onChangeDocType(docType);
            if(docType === EnumCodeIdentificationType.MENOR) {
                form.setFieldsValue({...form.getFieldsValue(), typeDoc:  undefined});
            }
        }*/
    }

    const initialValues = props.patient ? {
        ...props.patient,
        name: props.patient.nameNombre,
        surname1: props.patient.nameApellido1,
        surname2: props.patient.nameApellido2,
        dateBirt: props.patient.dateNacimiento && moment(props.patient.dateNacimiento),
        gender: props.patient.typeSexo,
        typeDoc: props.patient.typeIdentificacion,
        codeIdentification: props.patient.codeIdentificacion,
        email: props.patient.valueEmail,
        phone: props.patient.valueTelefono,
        idMutualidad: props.patient.idMutualidad,
        ididioma: props.patient.idIdioma,
        idAseguradora: props.patient.idAseguradora,
        cardNumber: props.patient.codeTarjetaSanitaria
    } : {typeDoc : EnumCodeIdentificationType.DNI};

    const typeDoc = (
            <Form.Item noStyle name='typeDoc' rules={[{required: true}]}>
                <Select onSelect={onChangeDocType} dropdownStyle={{minWidth: '8rem'}}
                        filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                        disabled={(props.editPatient || props.fromExistingPerson)}>
                    {typeDocList}
                </Select>
            </Form.Item>);

    return (
        <Modal title={props.editPatient ? t('editPatientButton') : t('newPatientButton')}
               style={{top: 40}}
               visible={props.visible}
               onCancel={() => props.onCancel()}
               onOk={() => props.onSubmit(form)}
               bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1.5rem'}}
               width={800}
               okText={t('buttons:save')} cancelText={t('buttons:cancel')}
               destroyOnClose
        >
            <Form layout='vertical' form={form} size='large' initialValues={initialValues}>
                <Row gutter={8}>
                    <Col span={8}>
                        <Form.Item label={t('namePatientTitle')} name='name' rules={[{required: true}]}
                                   validateStatus={FormUtils.isError('name', props.errorFields) ? 'error' : undefined}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('surname1Label')} name='surname1' rules={[{required: true}]}
                                   validateStatus={FormUtils.isError('surname1', props.errorFields) ? 'error' : undefined}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('surname2Label')} name='surname2'>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item label={t('dateBirthLabel')} name='dateBirt' rules={[{required: true}]}
                                   validateStatus={FormUtils.isError('dateBirt', props.errorFields) ? 'error' : undefined}>
                            <DatePicker format='L' style={{width: '100%'}}
                            onChange={onDatePickerChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name='gender' label={t('genderLabel')} rules={[]}>
                            <Select
                                filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                {genderList}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='ididioma' label={t('idioma')} rules={[]}>
                            <Select
                                        className='language-selector'
                                        options={[
                                            {value: 1, label: <><Flag style={{marginRight:'0.5rem'}} country='ES'/>{t('castellano')}</>},
                                            {value: 2, label: <><Flag style={{marginRight:'0.5rem'}} country='GB'/>{t('ingles')}</>},
                                            {value: 3, label: <><Flag style={{marginRight:'0.5rem'}} country='DE'/>{t('aleman')}</>}
                                        ]}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('codeIdentificationLabel')} name='codeIdentification'
                                    rules={[{required: !props.isMenor}]}
                                    validateStatus={FormUtils.isError('codeIdentification', props.errorFields) ? 'error' : undefined}
                                    help={FormUtils.getErrorMessage('codeIdentification', props.errorFields)}>
                            <Input maxLength={128} addonBefore={typeDoc} disabled={props.editPatient || props.fromExistingPerson}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item label={t('emailLabel')} name='email' rules={[{required: false}]}
                                   validateStatus={FormUtils.isError('email', props.errorFields) ? 'error' : undefined}
                                   help={FormUtils.getErrorMessage('email', props.errorFields)}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('phoneLabel')} name='phone'>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={8}>
                        <Form.Item label={t('aseguramientoLabel')} name='idMutualidad'>
                            <Select showSearch notFoundContent={true} allowClear onChange={onSelectMutualidad}
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                {(props.mutualidadList || []).map(m =>
                                    <Option key={m.idMutualidad} value={m.idMutualidad} label={m.nameMutualidad}>
                                        {m.nameMutualidad}
                                    </Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('insuranceEntityLabel')} name='idAseguradora'
                                rules={[{required: props.idMutualidad !== undefined}]}>
                            <Select showSearch notFoundContent={true} allowClear onChange={onSelectAseguradora}
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                {(props.aseguradoraList || []).map(a =>
                                    <Option key={a.idAseguradora} value={a.idAseguradora} label={a.nameAseguradora}>
                                        {a.nameAseguradora}
                                    </Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('cardNumberLabel')} name='cardNumber'
                                rules={[{required: props.idMutualidad !== undefined}]}
                                validateStatus={FormUtils.isError('cardNumber', props.errorFields) ? 'error' : undefined}
                                help={FormUtils.getErrorMessage('cardNumber', props.errorFields)}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>

                {props.currentTutor &&
                <Row gutter={8}>
                    <div className='tutores_title'>{t('tutoresTitle')} </div>

                    <Row key={props.currentTutor.idTutor} className='tutor_container' wrap={false}>
                        <Col flex='1 1 100%'>
                            <b>{props.currentTutor.codeIdentificacion}</b> {' ' + props.currentTutor.nameNombre + ' ' + props.currentTutor.nameApellido1 + (props.currentTutor.nameApellido2 ? ' ' + props.currentTutor.nameApellido2 : '')}
                        </Col>
                        <Col  className='icon_tutor'>
                            <Space size='small'>
                                {props.tutorConsentimiento ?
                                    <Button type='primary' className='icon_button'
                                            icon={<FontAwesomeIcon icon={faPen} style={{fontSize: '12px'}}/>}
                                            onClick={() => props.editTutor()}/>
                                    :
                                    <Button  size={'small'} className='consentimiento_button'
                                            // icon={<FontAwesomeIcon icon={faLock} style={{fontSize: '12px'}}/>}
                                            onClick={() => props.getTutorConsentimiento(props.currentTutor!.idPersona)}>
                                        {t('requestTutorAccess')}
                                    </Button>
                                }
                                <Button type='primary' danger className='icon_button'
                                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '12px'}}/>}
                                        onClick={() => props.deleteTutor()}/>
                            </Space>
                        </Col>
                    </Row>

                </Row>
                }

                {typeof props.currentTutor === 'undefined' ?
                    <span className='text_title'>
                        <FontAwesomeIcon icon={faPlus}
                                         style={{marginRight: '-0.5rem', cursor: 'pointer', color: '#40A9FF'}}
                        />
                        <Button type='link' style={{cursor: 'pointer'}} onClick={() => props.addTutor()}>
                            {t('addTutorButton')}
                        </Button>
                    </span>
                    : null
                }
            </Form>
        </Modal>
    )
}

export default NewPatient;