import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Rest} from '../../utils/utils';
import FormErrorField from '../../utils/form/formErrorField';
import WelcomeStepCompletedView from '../welcomeStepCompletedView';
import RegistroExternoDocumentModal from './registroExternoDocumentModal';
import AltaPrestadorFromExternalData from '../../login/entities/altaPrescriptorFromExternalData';
import AltaPrescriptorFormContainer from '../../login/AltaPrescriptorFormContainer';
import { FormInstance } from 'antd';
import LOGOERECETA from '../../images/logoEReceta.png';

export interface IState {
    prestadorRegistroData?: AltaPrestadorFromExternalData;
    loaded?: boolean;
    stepOne?: boolean;
    stepCompleted?: boolean;
    errorFields: FormErrorField[];
    documentModalVisible?: boolean;
}

class RegistroExternoContainer extends React.Component<WithTranslation & RouteComponentProps<{ idPrestador: string}>, IState> {

    public idPrestador: number = Number(this.props.match.params.idPrestador);

    public state: IState = {
        errorFields: [],
    };

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state.loaded ?
                <>
                    {this.state.stepOne &&
                        <div className='enlace_token_container'>
                            <div className='enlace_token_card_container'>
                                <div className='enlace_token_card' style={{width: '1024px'}}>
                                    <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                           
                                    <AltaPrescriptorFormContainer
                                        closeForm={this.closeForm}
                                        fromExternalAccess={true}
                                        showForm={true}
                                        existingPrestadorData={this.state.prestadorRegistroData}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.documentModalVisible &&
                        <RegistroExternoDocumentModal onClose={this.onCloseDocumentModal}
                            visible={this.state.documentModalVisible}
                            onAcceptDocumentModal={this.onAcceptDocumentModal}
                        />
                        

                    }
                    {this.state.stepCompleted &&
                        <WelcomeStepCompletedView
                            access={this.access}
                        />
                    }
                </>
                :
                <></>
        );
    }

    private loadData = () => {
        Rest<{ type: string, idPrestador: number }, AltaPrestadorFromExternalData>().operation({
            type: 'SelectPrestadorRegistroById',
            idPrestador: this.idPrestador,
        }).then(response => {
            if (response) {
                this.setState({
                    prestadorRegistroData: response,
                    loaded: true,
                    stepOne: true,
                });
            }
        });
    };

    private closeForm = (form?: FormInstance) => {
        this.setState({stepOne: false, documentModalVisible: true});
    };

    private onCloseDocumentModal = () => {
        this.setState({stepOne: true,documentModalVisible: false});
    };

    private onAcceptDocumentModal = () => {
        this.setState({documentModalVisible: false});
    }

    private access = () => {
        this.props.history.push('/login');
    };
}

export default withTranslation('welcome')(withRouter(RegistroExternoContainer));