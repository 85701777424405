import React, {ClipboardEvent, KeyboardEvent, useEffect} from 'react';

import { Alert, Button, Input, Form, FormInstance, Modal} from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Store } from 'rc-field-form/lib/interface';
import { IState as IStateContainer } from './LoginContainer';
import EnumCodeResultLogin from '../enum/EnumCodeResultLogin';
import './login.scss';
import { useState } from 'react';
import { useRef } from 'react';
import ForgotPassword from './ForgotPassword';

const Login = (props: {
    codeResult?: number;
    onSubmit: (values: Store) => void;
    onValidationFailed: () => void;
    tryAgain: () => void;
    onClickForgotPassword: (close?: boolean) => void;
    onForgotPassword: (values: Store) => void;
    validationPasswordFailed: () => void;
    closePasswordForm: () => void;
    onOtpCancel: () => void;
    visible?: boolean;
    onClose?: () => void;
    onCancelarRegenerarContraseña?: () => void;
} & IStateContainer) => {

    const { t } = useTranslation(['login']);

    const usernameInputRef = useRef<Input>(null);

    const [formOTP] = Form.useForm();

    const [autocomplete, setAutocomplete] = useState<string>('');

    useEffect(() => {
        if (props.otpCancelled) {
            formOTP.setFieldsValue({password: 'new-password'});
            setAutocomplete('new-password');
        } else {
            setAutocomplete('');
        }

    }, [formOTP, props.otpCancelled]);

    const clearOtpValues = () => {
        formOTP.setFieldsValue({
            ...formOTP.getFieldsValue(),
            txt_Key_1: undefined,
            txt_Key_2: undefined,
            txt_Key_3: undefined,
            txt_Key_4: undefined,
            txt_Key_5: undefined,
            txt_Key_6: undefined,
        });
    }

    const getErrorMessage = () => {

        if (props.codeResult === EnumCodeResultLogin.SUSCRIPCION_CADUCADA) {
            return <>{props.errorMessage}<br/>
                    <Button onClick={() => { window.location.href=props.linkRenovacion! }} 
                            type='link' size='small' style={{padding: 0}}>{t('renovarAhora')}</Button>
            </>
        } else {
            return props.errorMessage;
        }
    }

    return (
        <Modal
        className='modalLogin'
        style={{ top: 40 }}
        visible={props.visible}
        onCancel={props.forgotPasswordVisible ? props.onCancelarRegenerarContraseña : props.onClose}
        destroyOnClose={true}
        footer={false}
        >
            <div className='login__form'>
                {/* FORMULARIO LOGIN */}
                {!props.forgotPasswordVisible && 
                    <>
                        <Form size='large' onFinish={props.onSubmit} onFinishFailed={props.onValidationFailed}>
                            <Form.Item name='username' rules={[{ required: true }]}>
                                <Input autoFocus placeholder={t('username')} 
                                    prefix={<UserOutlined />} ref={usernameInputRef}/>
                            </Form.Item>
                            <Form.Item name='password' rules={[{ required: true }]}>
                                <Input.Password placeholder={t('password')} autoComplete={autocomplete}
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                                    prefix={<LockOutlined />} />
                            </Form.Item>
                            {props.warningMessage && <Alert message={props.warningMessage} type='warning' showIcon/>}
                            {props.errorMessage && <Alert message={getErrorMessage()} type='error' showIcon/>}
                            <Button type='primary' htmlType="submit"
                                size='large' block 
                                style={(props.warningMessage || props.errorMessage) ? {marginTop:'1.5rem'} : undefined}>{t('login')}</Button>
                        </Form>
                        <Button type='link' block style={{marginTop:'1.0rem'}} onClick={() => props.onClickForgotPassword()}>{t('forgotPassword')}</Button>
                    </>}

                {/* OTP CADUCADO */}
                {props.codeResult === EnumCodeResultLogin.CODE_OTP_CADUCADO &&
                    <>
                    {props.errorMessage && <Alert message={props.errorMessage} type='error' showIcon/>}
                    <Button onClick={props.tryAgain} style={{marginTop: '1rem'}}>{t('tryAgainButton')}</Button>
                </>}

                {/* RECORDAR CONTRASEÑA */}
                {props.forgotPasswordVisible && 
                    <ForgotPassword
                        mailSent={props.mailSent}
                        warningPasswordMessage={props.warningPasswordMessage}
                        errorPasswordMessage={props.errorPasswordMessage}
                        onForgotPassword={props.onForgotPassword}
                        validationPasswordFailed={props.validationPasswordFailed}
                        closePasswordForm={props.closePasswordForm} />}                  
            </div>
        </Modal>
    );
}

export default Login;
